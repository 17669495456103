import React, { useEffect, useMemo, useState } from 'react';
import { PageProps } from 'gatsby';
import {
  getCommonRecordDisplay,
  getFormFields,
} from '../../../modules/row-data/utils';
import { Card, message, Modal } from 'antd';
import Layout, { LayoutProps } from '../../../components/Layout';
import { QuickForm } from '../../../components/QuickForm';
import {
  useTableCreate,
  useTableLoad,
  useTableUpdate,
} from '../../../modules/row-data/queryHooks';
import { useAppContext } from '../../../modules/app';
import { Helmet } from 'react-helmet';
import { cloneDeep, startCase } from 'lodash';
import createPrivatePage from '../../../utils/createPrivatePage';

function Edit(props: PageProps) {
  const {
    params: { id, table: tableName },
    navigate,
  } = props;
  const tableResource = useAppContext().resources[tableName];
  const { colFields, relationFields, dataSetting } = tableResource;
  const loadResult = useTableLoad(tableName, {
    variables: {
      pk: id,
    },
    skip: !id,
  });
  const [changedFields, setChangedFields] = useState<Record<string, boolean>>(
    {}
  );
  const [create, createResult] = useTableCreate(tableName);
  const [update, updateResult] = useTableUpdate(tableName);

  const initialValues = id ? loadResult.data : {};
  const submitting = createResult.loading || updateResult.loading;
  const submitError = createResult.error || updateResult.error;
  const submitData = createResult.data || updateResult.data;

  const layoutProps = useMemo<Partial<LayoutProps>>(() => {
    const title = startCase(dataSetting?.title || tableName);
    return {
      pageTitle: title,
      breadcrumb: [
        { title: 'Home', path: '/' },
        { title: title, path: `/${tableName}/list` },
        { title: id ? 'Edit' : 'Create', path: '' },
      ],
    };
  }, [tableName, dataSetting?.title, id]);

  const fields = useMemo(() => {
    return getFormFields(tableResource, id ? 'update' : 'create');
  }, [tableResource, id]);

  useEffect(() => {
    if (!submitting && submitData) {
      const createdId = submitData?.id;
      Modal.success({
        content: 'Submit successful',
        onOk: () => {
          if (createdId) {
            navigate(`/${tableName}/edit/${createdId}`, { replace: true });
          }
        },
      });
    }
    if (submitError) {
      message.error(submitError.toString(), 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, submitError, submitData]);

  const onFinish = (values: any) => {
    if (id) {
      const updateValues = cloneDeep(values);

      // This delete unchanged relation fields for update
      // because update relation ARRAY have to use replace docs (extra mutation)
      // and the data have to transform if it keep raw query value (have __typename field)
      // we simply remove it if it no changed
      Object.keys(relationFields).forEach((key) => {
        if (updateValues[key] && !changedFields[key]) {
          delete updateValues[key];
        }
      });

      update({
        variables: {
          pk: id,
          data: updateValues,
        },
      });
    } else {
      create({
        variables: { data: values },
      });
    }
  };
  if (loadResult.loading) {
    return null;
  }
  return (
    <Layout {...layoutProps}>
      <Helmet
        title={`${tableName} ${
          id ? '/ ' + (getCommonRecordDisplay(loadResult.data) || '') : 'Create'
        }`}
      />
      <Card bodyStyle={{ paddingBottom: 0 }}>
        <QuickForm
          layout="vertical"
          name="basic"
          fields={fields}
          initialValues={initialValues}
          onFinish={onFinish}
          onValuesChange={(changed) => {
            const nextChangedFields = Object.fromEntries(
              Object.keys(changed).map((key) => [key, true])
            );
            setChangedFields((prev) => ({ ...prev, ...nextChangedFields }));
          }}
          actionButtons={[
            {
              children: 'Submit',
              htmlType: 'submit',
              type: 'primary',
              loading: submitting,
              disabled: submitting,
            },
            {
              children: 'Cancel',
              onClick: () => navigate(`/${tableName}/list`, { replace: true }),
            },
          ]}
        />
      </Card>
    </Layout>
  );
}

export default createPrivatePage(Edit);
